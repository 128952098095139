@import 'bootstrap/scss/functions';

$rps-brown: #78624e;
$rps-dark-brown: #584839;
$rps-yellow: #ffc700;
$rps-kidzrock-orange: #ef5c22;

$rps-light-gray: #efefef;
$rps--gray: #9f9f9f;

$rps-cloud-dark: #89c8eb;
$rps-cloud: #bee0f3;
$rps-cloud-light: #eff9ff;

$rps-bg-light: white;
$rps-bg-primary: $rps-brown;

$rps-text-light: white;
$rps-text-dark: black;
$rps-text-subdued: $rps--gray;
$rps-text-accent: $rps-yellow;

$rps-card-background: $rps-bg-light;

$rps-btn-primary: $rps-bg-primary;

.headline,
.headline-subtitle,
.call-to-action,
.title,
.subtitle,
.body,
.caption {
  &.light,
  &.light a {
    color: $rps-text-light !important;
  }
  &.dark,
  &.dark a {
    color: $rps-text-dark !important;
  }
  &.subdued,
  &.subdued a {
    color: $rps-text-subdued !important;
  }
  &.accent,
  &.accent a {
    color: $rps-text-accent !important;
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 2160px
);
