@import 'variables';
@import 'bootstrap/scss/mixins';

//thin - 100, light - 300, regular - 400, medium - 500, bold - 700, black - 900
$font-roboto: 'Roboto', sans-serif;
$font-roboto-condensed: 'Roboto Condensed', sans-serif;
$font-montserrat: 'Montserrat', 'Roboto', sans-serif;
$font-childish: 'Luckiest Guy', sans-serif;

//text styles
a {
  color: black;
}

.hero-headline {
  font-family: $font-roboto;
  font-weight: 900;
  font-size: 2rem;
}

@include media-breakpoint-up(sm) {
  .hero-headline {
    font-size: 3rem;
  }
}

@include media-breakpoint-up(md) {
  .hero-headline {
    font-size: 4rem;
  }
}

@include media-breakpoint-up(lg) {
  .hero-headline {
    font-size: 5rem;
  }
}

.headline {
  font-family: $font-roboto;
  font-weight: 900;
  font-size: 26px;
  &.material-icons,
  & .material-icons {
    font-size: 30px;
    line-height: 32px;
  }
}

.headline-subtitle {
  font-family: $font-roboto;
  font-weight: 400;
  font-size: 18px;
}

.call-to-action {
  font-family: $font-roboto;
  font-weight: 600;
  font-size: 18px;
}

.title {
  font-family: $font-roboto-condensed;
  font-weight: 300;
  font-size: 26px;
}

.subtitle {
  font-family: $font-roboto-condensed;
  font-weight: 300;
  font-size: 18px;
}

.body {
  font-family: $font-roboto;
  font-weight: normal;
  font-size: 18px;
  a {
    color: black;
  }
}

.caption {
  font-family: $font-roboto;
  font-weight: normal;
  font-size: 14px;
}

.childish {
  font-family: $font-childish;
  letter-spacing: 0.125rem;

  &.headline {
    font-size: 56px;
  }

  &.headline-subtitle {
    font-family: $font-montserrat;
    letter-spacing: normal;
    font-size: 36px;
    font-weight: 900;
    text-transform: uppercase;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  &.text-kidzrock {
    color: $rps-kidzrock-orange;
  }
}
