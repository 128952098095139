@import 'variables';

.btn {
  &.btn-pill {
    border-radius: 1.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &:not(:disabled):not(.disabled) {
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-primary {
  border: none;
  background-color: $rps-btn-primary;
  color: $rps-text-light;

  &:not(:disabled):not(.disabled) {
    &:focus {
      background-color: $rps-btn-primary;
    }
    &:hover,
    &:active {
      background-color: darken($rps-btn-primary, 15%);
      border-color: darken($rps-btn-primary, 25%);
    }
  }
}

.btn-kidzrock {
  background-color: $rps-kidzrock-orange;
  color: white;

  &:not(:disabled):not(.disabled) {
    &:focus {
      background-color: $rps-kidzrock-orange;
    }
    &:hover,
    &:active {
      background-color: darken($rps-kidzrock-orange, 15%);
      border-color: darken($rps-kidzrock-orange, 25%);
    }
  }
}
